var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout"},[(_vm.$route.name !== 'AssessDetailReport'
        && _vm.$route.name !== 'EmotionDetailReport'
        && _vm.$route.name !== 'BreathingDetailReport'
        && _vm.$route.name !== 'NutritionDetailReport'
)?_c('top-bar'):_vm._e(),_c('div',{staticClass:"container"},[(_vm.$route.name !== 'AssessDetail'
                && _vm.$route.name !== 'NutritionDetail'
                && _vm.$route.name !== 'AssessStatistics'
                && _vm.$route.name !== 'BreathingDetail'
                && _vm.$route.name !== 'BreathingDetailContrast'
                && _vm.$route.name !== 'EmotionDetail'
                && _vm.$route.name !== 'AssessDetailContrast'
                && _vm.$route.name !== 'EmotionDetailContrast'
                && _vm.$route.name !== 'GestationalAge'
                && _vm.$route.name !== 'AssessDetailReport'
                && _vm.$route.name !== 'EmotionDetailReport'
                && _vm.$route.name !== 'BreathingDetailReport'
                && _vm.$route.name !== 'NutritionDetailReport'
            )?_c('slide-bar'):_vm._e(),_c('div',{staticClass:"content"},[_c('router-view',{key:_vm.key})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }